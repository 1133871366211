import { isEmpty, isNil, reject } from 'ramda'
import { MpxUser, ProgramEngagementUser, UserId, UserRef } from '../types'
import { OrgUserSearchResult } from '../types/OrgUserTypes'

class BaseUserModel<T extends MpxUser | OrgUserSearchResult | ProgramEngagementUser | UserRef> {
  user: T
  constructor(user: T) {
    this.user = user
  }
  matchUserId(userId: UserId) {
    return userId === this.user.userId
  }
}

class ExtendedUserModel<T extends MpxUser | OrgUserSearchResult> extends BaseUserModel<T> {
  matchUserId(userId: UserId) {
    return userId === this.user.userId
  }

  isGrower() {
    return this.user.persona === 'grower'
  }
  isAdmin() {
    return this.user.persona === 'admin'
  }
  isAdvisor() {
    return this.user.persona === 'advisor'
  }
}

export class UserRefModel extends BaseUserModel<UserRef> {
  get displayNameFull() {
    const { userPrimaryEmail, familyName, givenName } = this.user
    return !!givenName ? reject(isNil, [givenName, familyName]).join(' ') : userPrimaryEmail
  }
}

export class UserModel extends ExtendedUserModel<MpxUser> {
  get displayNameFull() {
    const { username, userInfo } = this.user
    return !!userInfo?.givenName
      ? reject(isNil, [userInfo.givenName, userInfo.familyName]).join(' ')
      : username
  }

  get displayNameGiven() {
    const { username, userInfo: { givenName } = {} } = this.user
    return !!givenName ? givenName : username
  }

  get displayInitials() {
    const { userInfo: { givenName, familyName } = {} } = this.user
    return !!givenName || !!familyName
      ? `${!!givenName?.charAt(0) ? givenName?.charAt(0) : ''}${
          familyName?.charAt(0) ? familyName?.charAt(0) : ''
        }`
      : null
  }

  get orgId() {
    return (
      this.user.owningOrgId ??
      this.user.userOrganization?.accountId ??
      this.user.userOrganization?.id
    )
  }

  get canViewOrgs() {
    // this is not ideal, but is the best case scenario based on currently available data
    return this.isAdmin()
  }
}

export class OrgUserModel extends ExtendedUserModel<OrgUserSearchResult> {
  get displayNameFull() {
    const { email, familyName, givenName } = this.user
    return !!givenName ? reject(isNil, [givenName, familyName]).join(' ') : email
  }

  get displayNameGiven() {
    const { email, givenName = {} } = this.user
    return !!givenName ? givenName : email
  }

  get displayInitials() {
    const { givenName, familyName } = this.user
    return !!givenName || !!familyName
      ? `${!!givenName?.charAt(0) ? givenName?.charAt(0) : ''}${
          familyName?.charAt(0) ? familyName?.charAt(0) : ''
        }`
      : null
  }

  get advisor() {
    return this.user.advisor
  }
  get advisorDisplayNameFull() {
    const { email, familyName, givenName } = this.advisor || {}
    return !!givenName ? reject(isNil, [givenName, familyName]).join(' ') : email
  }

  get email() {
    return this.user.email
  }
  get userId() {
    return this.user.userId
  }

  get orgId() {
    return this.user.org?.id || 'root'
  }

  get searchResultsDisplayNameMarkdown() {
    const { matches } = this.user
    const familyName = matches?.familyName ? matches.familyName : this.user.familyName
    const givenName = matches?.givenName ? matches.givenName : this.user.givenName
    const email = matches?.email
      ? matches.email
      : !this.user.givenName && !this.user.familyName
      ? this.user.email
      : ''
    const fullName = reject(isNil, [givenName, familyName]).join(' ')
    const parts = reject(isEmpty, [fullName, email])
    return (parts.length === 2
      ? [parts[0], `<small style="opacity: 0.7">${parts[1]}</small>`]
      : parts
    ).join('  \n')
  }

  get canViewOrgs() {
    // this is not ideal, but is the best case scenario based on currently available data
    return this.isAdmin()
  }
}

export class ProgramEngagementUserModel extends BaseUserModel<ProgramEngagementUser> {
  get displayNameFull() {
    const { userPrimaryEmail, familyName, givenName } = this.user
    return !!givenName ? reject(isNil, [givenName, familyName]).join(' ') : userPrimaryEmail
  }

  get displayNameGiven() {
    const { userPrimaryEmail, givenName = {} } = this.user
    return !!givenName ? givenName : userPrimaryEmail
  }

  get displayInitials() {
    const { givenName, familyName } = this.user
    return !!givenName || !!familyName
      ? `${!!givenName?.charAt(0) ? givenName?.charAt(0) : ''}${
          familyName?.charAt(0) ? familyName?.charAt(0) : ''
        }`
      : null
  }

  get email() {
    return this.user.userPrimaryEmail
  }
  get userId() {
    return this.user.userId
  }
}
